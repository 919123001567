export const interesteList = [
    {
        id: 1,
        value: 1,
        label: 'Solar',
        name: 'Solar'
    },
    {
        id: 2,
        value: 2,
        label: 'Battery',
        name: 'Battery'
    },
    {
        id: 3,
        value: 3,
        label: 'Solar + Battery',
        name: 'Solar + Battery'
    },
]

export const roleList = [
    {
        id: 1,
        value: 1,
        label: 'Supervisor',
        name: 'Supervisorr'
    },
    {
        id: 2,
        value: 2,
        label: 'Worker',
        name: 'Worker'
    },
]