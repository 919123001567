import { mapGetters, mapMutations } from 'vuex'
import download from "downloadjs"
import storage from '@/utils/storage'
import departmentData from '@/config/department.data'
const commonMixins = {
    methods: {
        back(){
            history.go(-1)
        },
        downloadFile(file){
            if(!file) return 
            download(file)
        },
    },
    computed: {
        getAction(){
            return (actionType, isAdmin = false) => {
                if(isAdmin) return false
                const actions = storage.getLocalData('actions')
                if(actions){
                    const actionArr = JSON.parse(actions)
                    const filters = actionArr[0].actions.filter(r => r.id == actionType)
                    if(filters.length > 0){
                        const checked = filters[0].value
                        if(checked) return true
                    }
                }
                return false
            }
        },
        getShowBlock(){
            return blockClass => {
                const depClass = storage.getLocalData('dep_class')
                if(depClass == 1) return true
                const status = departmentData.filter(r => r.value == depClass)
                return status.length > 0
            }
        },
        getDepClass(){
            return storage.getLocalData('dep_class')
        },
        getActionName(){
            return actionName => {
                switch(Number(actionName)){
                    case 1:
                        return 'Create'
                    case 2:
                        return 'Edit'
                    case 3:
                        return 'Query'
                    case 4:
                        return 'Delete'
                    case 5:
                        return 'Distribution'
                    case 6:
                        return 'Send'
                    case 7:
                        return 'Finish'
                }
            }
        },
        ...mapGetters({
            getConfig: 'getConfig'
        })
    }
}
export default commonMixins