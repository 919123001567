import request from '@/request'

export const getRegionList = (data = {}) => {
    return request({
      url: '/home/region/get_region_list',
      method: 'get',
      params: data
    })
}
export const updateRegion = (data = {}) => {
  return request({
    url: '/admin/region/update_region',
    method: 'post',
    data
  })
}
export const removeRegion = (data = {}) => {
  return request({
    url: '/admin/region/remove_region',
    method: 'post',
    data
  })
}