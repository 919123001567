const departmentData = [
    {
        label: 'Sale',
        value: 1,
    },
    {
        label: 'Engineering',
        value: 2
    },
    {
        label: 'Installation',
        value: 3
    },
    {
        label: 'Financial',
        value: 4
    },
    {
        label: 'Purchasing',
        value: 5
    },
    {
        label: 'After sale',
        value: 6
    }   
]

export default departmentData