<template>
    <div class="home_page h100">
      <img v-if="getConfig.home_image_url" :src="getConfig.home_image_url" class="home_img">
      <div class="sub_heading">{{getConfig.sub_heading || '-'}}</div>
      <div class="recommended">
        <div class="recommended_item region" @click="select('region')">
           <span class="name">Location  <component class="arrow" :is="$antIcons['DownOutlined']" /></span>
           <strong class="value">{{formData.region || 'Choose location'}}</strong>
        </div>
        <div class="recommended_item range" @click="select('range')">
          <span class="name">Electricity Bill  <component class="arrow" :is="$antIcons['DownOutlined']" /></span>
          <strong class="value">{{formData.bill == 0 ? 'What is your quarterly electricity bill?' : `$${formData.bill}`}}</strong>
        </div>
        <div class="recommended_item interested" @click="select('interested')">
          <span class="name">Interested in <component class="arrow" :is="$antIcons['DownOutlined']" /></span>
          <strong class="value">{{formData.interested || 'What type of products are you interested in?'}}</strong>
        </div>
        <div class="recommended_item go">
          <a-button type="primary" class="btn primary" @click="go">Recommended</a-button>
        </div>
      </div>
      <div class="heading">{{getConfig.heading || '-'}}</div>
      <a-drawer
        :title="title"
        placement="bottom"
        v-model:visible="drawerStatus"
      >    <div class="container">
              <!--Region-->
              <div class="region" v-if="actionType == 'region'">
                 <div @click="choose('region', region.region_name)" :class="['region_item', {on : formData.region == region.region_name}]" v-for="(region, idx) in regionList" :key="idx">{{region.region_name}}</div>
              </div>
              <!--range-->
              <div class="range" v-if="actionType == 'range'">
                  <div class="curr_bill">Current bill: ${{formData.bill}}</div>
                  <div class="slider">
                    <a-slider @afterChange="() => choose('bill', formData.bill)" :min="200" :step="10" :max="2000" v-model:value="formData.bill" />
                    <div class="min"><i class="value">min /</i>200</div>
                    <div class="max"><i class="value">max /</i>2000</div>
                  </div>
              </div>
              <!--Interested-->
              <div class="region" v-if="actionType == 'interested'">
                  <div @click="choose('interested', interested.name)" :class="['region_item', {on : formData.interested == interested.name}]" v-for="(interested, idx) in interesteList" :key="idx">{{interested.name}}</div>
              </div>
           </div>
      </a-drawer>
 </div>
</template>
<script>
import commonMixins from '@/mixins/commonMixins'
import { getRegionList } from '@/apis/region'
import { interesteList } from '@/config/const'
export default {
  name: 'Home',
  mixins: [commonMixins],
  data(){
    return {
      title: '',
      drawerStatus: false,
      actionType: '',
      regionList: [],
      interesteList,
      formData:{
        region: '',
        bill: 0,
        interested: ''
      },
      tempBill: 200
    }
  },
  methods: {
     select(actionType){
       this.actionType = actionType
       switch(actionType){
          case 'region':
             this.title = 'Choose location'
             break;
          case 'range':
             this.title = 'What is your quarterly electricity bill?'
             break;
          case 'interested':
             this.title = 'What type of products are you interested in?'
             break;
       }
       this.drawerStatus = true
     },
     choose(key, value){
       this.formData[key] = value
       this.drawerStatus = false
     },
     /**
      * 跳转
      */
     go(){
        //检查区域
        if(this.formData.region == ''){
          return this.select('region')
        }
        //检查账单
        if(this.formData.bill == 0){
          return this.select('range')
        }
        //检查偏好
        if(this.formData.interested == ''){
          return this.select('interested')
        }
        const interested = this.interesteList.filter(r => r.name == this.formData.interested)
        const region = this.regionList.filter(r => r.region_name == this.formData.region)
        this.$router.push({
          path: '/recommend',
          query: {
             bill: this.formData.bill,
             interested: interested[0].id,
             region: region[0].region_id
          }
        })
     },
      async getRegionListHandler(){
        const res = await getRegionList()
        const list = res.list.map(r => {
            r.label = r.region_name
            r.value = r.region_id
            return r
        })
        this.regionList = list
      }
  },
  created(){
    this.getRegionListHandler()
  }
}
</script>
<style lang="stylus" scoped>
.home_page
    display flex
    flex-direction column
    align-items center
    padding 60px 0
    overflow-x hidden
    .home_img
        width 250px
    .sub_heading
        padding 10px 0
        text-align center
        color #2f4056
        font-size 22px
        display block
        line-height 40px
    .heading
        font-size 30px
        text-align center
        line-height 80px
        display block
    .recommended
        width 1000px
        margin 10px 0
        display flex
        padding 10px 20px
        background #fff
        box-shadow 0 10px 10px 0 rgba(0,0,0,.05)
        border-radius 20px
        .recommended_item
            margin 5px
            display flex
            flex-direction column
            cursor pointer
            &.region
                width 200px
            &.range
                width 300px
            &.interested
                width 350px
            &.go
                width 150px
                .btn
                    line-height 20px
                    height 50px
                    font-size 18px
            .name
                display block
                color #abb1bf
                font-size 13px
            .value
                height 40px
                line-height 40px
                display block
  .arrow
    color #43af52
    padding-left 20px   
.region
.interested
    display flex
    .region_item
        min-width 60px
        padding 5px 10px
        background rgba(0,0,0,.03)
        border-radius 10px
        margin 0 10px
        text-align center
        cursor pointer
        transition .5s
        &:hover
        &.on
            background #43af52
            color #fff
.range .slider
    position relative
    .min
        position absolute
        left 0
    .max
        position absolute
        right 0
    .value
        font-size 12px
        color #abb1bf
        font-style normal
        margin-right 5px
        
</style>